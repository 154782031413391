import { graphql } from 'gatsby';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import GoogleAdsense from '../components/google-adsense';
import Layout from '../components/layout';
import NavLink from '../components/navlink';
import SEO from '../components/seo';
import { localizedUrl } from '../utils/js/locale-utils';

const HelpPage = ({ intl, data }) => {
  const { allMarkdownRemark: { edges } } = data;

  const helpBlocks = [];
  edges.forEach((n, index) => {
    let columnClasses = 'column is-three-quarters';
    let messageClasses = 'message';
    if (index % 2) columnClasses += ' is-offset-one-quarter';
    switch (index % 4) {
      case 1: messageClasses += ' is-danger';
        break;
      case 2: messageClasses += ' is-info';
        break;
      case 3: messageClasses += ' is-warning';
        break;
      default:
        messageClasses += ' is-primary';
    }

    helpBlocks.push(
      <div className="columns m-t-lg" key={n.node.id}>
        <div className={columnClasses}>
          <article className={messageClasses} index={index}>
            <div className="message-header">
              <h2 className="is-size-5">{n.node.frontmatter.question}</h2>
            </div>
            <div className="message-body content">
              <div
            // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: n.node.html }}
              />
            </div>
          </article>
        </div>
      </div>,
    );
  });

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'help.title' })} />
      <section className="container section">
        <h1 className="title is-4">
          <FormattedMessage id="help.title" />
        </h1>
        <p>
          <FormattedMessage id="help.intro" />
          <NavLink to={localizedUrl('/contact')}>
            <FormattedMessage id="help.introLink" />
          </NavLink>
        </p>
        {helpBlocks}
        <div className="columns m-t-lg" key="ad">
          <div className={`column is-three-quarters ${helpBlocks % 2 ? '' : 'is-offset-one-quarter'}`}>
            <GoogleAdsense
              style={{ display: 'block' }}
              format="fluid"
              layoutKey="-f7+4w+7r-eg+48"
              client="ca-pub-1569243764016253"
              slot="1897494315"
              responsive="true"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___id] },
      filter: { fileAbsolutePath: {regex: "/help/.*\\.md$/"} }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            id
            question
          }
        }
      }
    }
  }
`;

HelpPage.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(HelpPage);
